.overlay {
	background: rgba(black, 0.7);
	position: absolute;
	top: 0;
	width: 100%;
	float: left;
	z-index: 100;
	opacity: 0;
	transition: opacity 0.5s ease;
	&.open {
		opacity: 1;

		transition: opacity 0.5s ease; } }
#contact-form, #enquiry-form {
	@include full;
	@include breakpoint($sub-tablet) {
		@include span(10); } }
.tt-dropdown-menu {
	@include full;
	padding: $margin/2;
	background: #FFF;
	border: 1px solid $dark-grey;
	.tt-dataset-regions, .tt-dataset-villages, .tt-dataset-hotels {
		@include full; } }

.tt-suggestion.tt-cursor {
	background: $light-grey; }
span.twitter-typeahead {
	@include full; }

.popup-form {
	@extend .container;
	padding: $margin;
	background: #FFF;
	position: relative;
	@include breakpoint($desktop) {
		padding: $margin*2; }


	hr {
		margin-bottom: 30px;
		@include full; } }

.booking-form-w {
	@include full;
	@include breakpoint($sub-tablet) {
		@include span(11); } }
.children-w {
	@include full;
	.input-field {
		margin-bottom: $margin; } }
.hidden-field {
	display: none; }
#gallery {
	display: none; }
.datepicker.dropdown-menu {
	z-index: 1000000000!important; }
.inline-radio {
	ul {
		@include full;
		li {
			float: left;
			margin-right: $margin; } } }
.input-w {
	@include full;
	&.input-2x {
		@include full;
		.input-field {
			@include full;
			margin-bottom: $margin;
			@include breakpoint($sub-tablet) {
				margin-bottom: 0;
				@include span(6 nobreak);
				&:nth-child(even) {
					@include span(last 6 nobreak); } } } }
	.check-in, .adults {
		@include full;
		margin-bottom: $margin;
		@include breakpoint($sub-tablet) {
			@include span(6 nobreak);
			margin-bottom: 0; } }
	.check-out, .children {
		@include full;
		@include breakpoint($sub-tablet) {
			@include span(last 6 nobreak); } }
	.sub-label {
		margin-top: $margin;
		display: none;
		@include full; } }


.error {
	@include full;
	border: 1px solid $red;
	padding: $margin;
	margin-bottom: $margin;
	ul {
		margin-bottom: 0;
		padding-bottom: 0;
		li {
			color: $red;
			&:last-child() {
				margin-bottom: 0;
				padding-bottom: 0; } } } }
.body-w {
	@include full;
	background: $almost-white;
	padding: 0 $margin;
	.body {
		padding: $margin*2 0;
		@extend .container;
		.main-w {
			@include full;
			margin-bottom: $margin;
			@include breakpoint($sub-tablet) {
				@include span(7 nobreak);
				margin-bottom: 0;
				&.home {
					@include span(13 of 24 nobreak); } }
			@include breakpoint($sub-desktop) {
				@include span(8 nobreak);
				margin-bottom: 0;
				&.home {
					@include span(13 of 24 nobreak); } }
			.blog-w {
				@include full;
				@include breakpoint($sub-tablet) {
					@include span(11); }
				a.blog {
					@include full;
					text-decoration: none;
					@extend .content;
					position: relative;
					margin-bottom: $margin*2;
					border-bottom: 1px solid $light-grey;
					padding-bottom: $margin*2;
					@include breakpoint($sub-tablet) {
						min-height: auto;
						padding-bottom: $margin;
						margin-bottom: $margin; }
					@include breakpoint($sub-desktop) {
						min-height: auto; }
					&:hover {
						cursor: pointer;
						h5 {
							text-decoration: underline;
							color: $body-color; } }
					.blog-image {
						@include full;
						margin-bottom: $margin;
						@include breakpoint($sub-desktop) {
							margin-bottom: 0;
							@include span(5 nobreak); }
						img {
							width: 100%;
							float: left; } }
					.blog-content {
						@include full;
						@include breakpoint($sub-desktop) {
							@include span(last 13 of 24 nobreak); }
						h5 {
							@include breakpoint($sub-tablet $sub-desktop) {
								@include fontSize(18px,22px); } }

						p {
							margin-bottom: 0;
							@include breakpoint($sub-desktop) {
								position: absolute;
								bottom: 0;
								margin-bottom: $margin*2; } } } } } }
		.sidebar-w {
			@include full;
			@include breakpoint($sub-tablet) {
				@include span(last 35 of 96 nobreak); }
			@include breakpoint($sub-desktop) {
				@include span(last 29 of 96 nobreak); }
			.filter-form {
				@include full; }
			.sidebar-spacer {
				margin-bottom: $margin;
				@include full;
				&.x2 {
					margin-bottom: $margin*2; } }
			.sidebar {
				color: $dark-grey;
				@include full;

				border-top: 1px solid $red;
				padding: $margin;
				&.mb {
					margin-bottom: $margin; }
				&:last-child() {
					border-bottom: 1px solid $red; }
				&.last-filter-sidebar {
					margin-bottom: $margin*2; }
				&.open {
					.sidebar-title.filter-title {
						&:after {
							content: '-';
							padding-top: 1px; } }
					ul.filter-list {
						display: block; } }

				&.button {
					padding: 0;
					@include full;
					border-top: 0;
					margin-bottom: $margin;

					a.btn {
						color: $almost-white;
						background: $green;
						@include full;
						text-transform: uppercase;
						font-weight: 800;
						text-decoration: none;
						text-align: center;
						padding: $margin/2 $margin;
						border: 1px solid $green;
						&:hover {
							background: darken($green, 5%); } }
					a.send {
						text-decoration: underline;
						text-align: center;
						color: $dark-grey;
						@include full;
						padding: $margin/2 $margin;
						@include fontSize(14px,18px);
						&:hover {
							text-decoration: underline; } } }
				&.title-border-bottom {
					border-top: 0;
					padding: 0;
					.sidebar-title {
						border-bottom: 1px solid $red;
						padding: 10px 20px; }
					.sidebar-content {
						padding: 0 $margin; } }

				&.red-title {
					padding: 0;
					@include full;

					.sidebar-title {
						background: $red;
						color: $almost-white;
						padding: $margin/2 $margin; }


					.sidebar-content {
						padding: 0 $margin; }

					&.border {
						border: 1px solid $red;
						margin-bottom: $margin; } }

				&.testimonials {
					border-top: 0;
					@include full;
					background: url('../images/quotes@2x.png') $light-grey 10px 10px no-repeat;
					background-size: 20px;
					margin-bottom: $margin;
					position: relative;
					a.next {
						background: url('../images/chevron-down@2x.png') $light-grey 0 0 no-repeat;
						background-size: 14px;
						width: 14px;
						height: 9px;
						float: left;
						position: absolute;
						top: 50%;
						right: $margin;
						z-index: 99; }
					a.prev {
						background: url('../images/chevron-up@2x.png') $light-grey 0 0 no-repeat;
						background-size: 14px;
						width: 14px;
						height: 9px;
						float: left;
						position: absolute;
						top: 40%;
						right: $margin;
						z-index: 99; }
					.testimonials-w {
						@include full;
						.testimonial {
							@include full;
							blockquote {
								@include full;
								color: $red;
								padding: 0;
								margin: 0;
								padding-top: $margin/2;
								padding-left: $margin;
								padding-right: $margin*2;
								font-weight: 600;
								margin-bottom: $margin/2;
								@include fontSize(16px,20px);
								&.truncate {
									height: 100px; } }
							.client {
								color: $dark-grey;
								padding-left: $margin;
								@include fontSize(15px,18px); } } } }
				label {
					color: $body-color; }

				.sidebar-title {
					color: $dark-grey;
					@include fontSize(15px,18px);
					margin-bottom: $margin;
					text-transform: uppercase;
					font-weight: 800;
					&.no-mb {
						margin-bottom: 0; }
					&.filter-title {
						margin-bottom: 0;
						cursor: pointer;
						position: relative;
						&:after {
							content: '+';
							padding-top: 4px;
							width: 20px;
							text-align: center;
							height: 20px;
							float: right;
							position: absolute;
							right: 0;
							padding-left: 1px;
							top: 50%;
							font-size: 24px;
							@include br(100px);
							margin-top: -$margin/1.5;
							color: $dark-grey; }
						&:hover {
							&:after {
								opacity: 0.7; } } } }
				ul.filter-list {
					display: none;
					padding-top: $margin; }


				.sidebar-content {
					@extend .content;
					ul {
						li {
							@include fontSize(14px,24px);
							margin-bottom: $margin/2;
							font-weight: 800;
							color: lighten($dark-grey, 10%); }
						&.list {
							padding-left: 0;
							li {
								list-style-type: none;
								text-transform: uppercase;
								margin-bottom: 0;
								a {
									color: $red;
									&:hover {
										text-decoration: underline; } } } } } }


				.why-book {
					margin-top: -$margin;
					ul {
						li {
							position: relative;
							padding: $margin $margin*2;
							padding-left: 85px;
							border-bottom: 1px solid $red;
							&:last-child() {
								border-bottom: 0; }
							&:before {
								content: '';
								float: left;
								position: absolute;
								left: 0;
								height: 60px;
								width: 70px; }
							h5 {
								@include fontSize(14px,20px);
								color: $heading-color;
								font-weight: 600;
								@include breakpoint($sub-tablet $sub-desktop) {
									@include fontSize(12px,18px); } }
							p {
								@include fontSize(14px,18px);
								color: $body-color;
								@include breakpoint($sub-tablet $sub-desktop) {
									@include fontSize(12px,18px); } }

							&.insider-knowledge {
								&:before {
									background: url(../images/insider-knowledge@2x.png) 70% center no-repeat;
									background-size: 43px; } }
							&.unique-retreats {
								&:before {
									background: url(../images/unique-retreats@2x.png) center center no-repeat;
									background-size: 35px; } }

							&.best-rates {
								&:before {
									background: url(../images/best-rates@2x.png) center center no-repeat;
									background-size: 45px; } }

							&.flexibility {
								&:before {
									background: url(../images/flexibility@2x.png) 80% center no-repeat;
									background-size: 48px; } } } } } } } } }


.hotels-w {
	@include full;
	.hotel {
		@include full;
		border-top: 1px solid $light-grey;
		border-bottom: 1px solid $light-grey;
		margin-bottom: $margin;
		@include breakpoint($sub-tablet) {
			@include span(3 of 6 nobreak);
			&:nth-child(2n) {
				@include span(last 3 of 6 nobreak); } }
		@include breakpoint($sub-desktop) {
			@include span(2 of 6 nobreak);
			&:nth-child(2n) {
				@include span(2 of 6 nobreak); }
			&:nth-child(3n) {
				@include span(last 2 of 6 nobreak); } }

		.hotel-img {
			@include full;
			margin-bottom: $margin/2;
			img {
				width: 100%; } }
		.hotel-i {
			padding: $margin;
			padding-bottom: $margin/2;
			border-left: 1px solid $light-grey;
			border-right: 1px solid $light-grey;
			.hotel-name {
				@include fontSize(17px,22px);
				font-weight: 800;

				height: 122px;
				a {
					color: $dark-grey;
					text-decoration: none;
					cursor: pointer;
					&:hover {
						text-decoration: underline; } } }


			.hotel-region {
				@include fontSize(14px,18px);
				padding-bottom: $margin/2;
				margin-bottom: $margin/2;
				border-bottom: 1px solid $red;
				height: 30px;
				a {
					color: $dark-grey;
					text-decoration: none;
					cursor: pointer;
					&:hover {
						text-decoration: underline; } } }

			.hotel-review {
				@include fontSize(12px,18px);
				color: $body-color;
				height: 75px; } }

		.hotel-controls {
			@include full;
			height: 52px;
			overflow: hidden;
			position: relative;
			border-top: 1px solid $light-grey;
			.hotel-price {

				padding: $margin/2 $margin;
				@include fontSize(14px,18px);
				float: left;
				border-left: 1px solid $light-grey;
				span {
					@include full;
					@include fontSize(11px,11px); } }
			a.hotel-favourite {
				padding: $margin/2 $margin;
				border-left: 1px solid $light-grey;
				float: left;
				background: url(../images/favourite@2x.png) center center no-repeat;
				background-size: 16px;
				position: absolute;
				top: 0;
				right: 45px;
				width: 45px;
				height: 52px;
				cursor: pointer;
				&:hover, &.favourite {
					background: url(../images/favourite-roll@2x.png) center center no-repeat;
					background-size: 16px;
					cursor: pointer; } }



			a.hotel-map {
				padding: $margin/2 $margin;
				border-left: 1px solid $light-grey;
				border-right: 1px solid $light-grey;
				float: left;
				background: url(../images/location@2x.png) center center no-repeat;
				background-size: 12px;
				position: absolute;
				top: 0;
				right: 0;
				width: 45px;
				height: 52px;
				cursor: pointer;
				&:hover {
					background: url(../images/location-roll@2x.png) center center no-repeat;
					background-size: 12px;
					cursor: pointer; } } }


		a.hotel-availability {
			@include full;
			background: $green;
			color: #FFF;
			text-align: center;
			@include fontSize(15px,14px);
			padding: $margin/2 0;
			text-decoration: none;
			cursor: pointer;
			&:hover {
				background: darken($green, 5%); } } } }

.call-henrik {
	background: url('../images/call-henrik@2x.jpg') #767475 0 0 no-repeat;
	background-size: 215px;
	border-bottom: 4px solid $red;
	color: $almost-white;
	height: 77px;
	margin-top: $margin;
	@include full;
	text-align: right;
	padding: 25px $margin;
	@include fontSize(16px,24px);
	margin-bottom: $margin;
	a.btn {
		background: $green;
		color: $almost-white;
		margin-left: $margin;
		font-weight: 500;
		text-decoration: none;
		padding: $margin/2 $margin;
		display: none;
		float: right;
		@include fontSize(16px,24px);
		margin-top: -10px;
		@include breakpoint($sub-desktop) {
			display: block; } } }

.call-henrik-sidebar {
	background: url('../images/henrik-photo.jpg') #767475 right 0 no-repeat;
	background-size: 82px;
	color: $almost-white;
	height: 80px;
	@include full;
	text-align: left;
	padding: 15px $margin;
	@include fontSize(16px,24px);
	@include breakpoint($sub-tablet $sub-desktop) {
		@include fontSize(13px,16px);
		padding-top: 23px; }
	h3 {
		@include fontSize(13px,16px);
		margin-bottom: 5px;
		@include breakpoint($sub-tablet $sub-desktop) {
			@include fontSize(11px,14px); } }
	p {
		@include fontSize(12px,16px);
		@include breakpoint($sub-tablet $sub-desktop) {
			@include fontSize(11px,14px); }
		a {
			color: #FFF; } }
	.henrik-title {
		display: none;
		&.current {
			display: block; }
		a {
			color: #FFF; } } }
.call-henrik-sidebar-btn {
	background: $green;
	margin-bottom: $margin;
	@include full;

	a {
		color: $almost-white;
		width: 50%;
		padding: $margin/2 0;
		font-weight: 600;
		text-align: center;
		text-decoration: none;
		float: left;
		background: darken(#767475,4%);
		border-right: 1px solid darken(#767475, 8%);
		&:hover {
			background: darken(#767475, 8%); }
		&:nth-child(even) {
			border-right: 0; } } }

.hotel-summary {
	@include full;
	margin-bottom: $margin; }

.single-w {
	@include full;
	margin-bottom: $margin*2;
	@extend .content;
	.posted {
		@include full;
		margin-bottom: $margin;
		span {
			background: $green;
			color: #FFF;
			float: left;
			width: auto;
			clear: both;
			@include fontSize(12px,20px);

			padding: $margin/2 $margin; } } }
.map-w {
	@include full;
	margin-bottom: $margin*2; }
.map-content {
	@include full; }
.map-hotel-w {
	@include full; }
.map-img-w {
	@include full;
	margin-bottom: 10px;
	height: 100px;
	background-position: 50% 50%!important;
	background-size: cover!important; }
.pagination-w {
	@include full;
	margin-bottom: $margin*2;
	ul {
		float: left;
		margin: 0;
		padding: 0;

		li {
			float: left;
			width: auto;
			clear: none;
			list-style-type: none;
			padding: $margin/2 $margin;
			color: $body-color;
			border: 1px solid $light-grey;
			border-right: 0px;
			&.active {
				background: $light-grey; }
			&:last-child {
				border-right: 1px solid $light-grey; }
			&:nth-child(1) {
				display: none; }
			a {
				width: 100%;
				float: left;
				text-decoration: none;

				&:hover {
					background: lighten($light-grey,5%); } } } } }

.team-member {
	@include full;
	padding-bottom: $margin;
	margin-bottom: $margin;
	padding-top: $margin;
	border-bottom: 1px solid $light-grey;
	.profile-image {
		@include full;
		img {
			width: 100%;
			margin-bottom: $margin; }
		@include breakpoint($sub-tablet) {
			@include span(4 nobreak);
			img {
				margin-bottom: 0; } } }
	.profile-copy {
		@include full;
		@include breakpoint($sub-tablet) {
			@include span(last 7 nobreak); } } }
.message-w {
	text-align: center;
	color: $dark-grey;
	background: $light-grey;
	opacity: 0.9;
	width: 100%;
	position: fixed;
	bottom: 0;
	padding: $margin/2; }
.gallery-icon {
	background: url(../images/gallery-icon.png) rgba(0,0,0,0.5) center center no-repeat;
	position: absolute;
	bottom: 20px;
	color: #FFF;
	right: 0px;
	padding: $margin $margin;
	float: right;
	font-weight: 600;
	background-size: 20px;
	text-align: center;
	@include fontSize(14px,14px);
	&:hover {
		cursor: pointer; }
	@include breakpoint($sub-tablet) {
		background: url(../images/gallery-icon.png) rgba(0,0,0,0.5) 90% center no-repeat;
		padding: 11px $margin;
		background-size: 30px;
		padding-right: 70px; }

	span {
		display: none;
		@include breakpoint($sub-tablet) {
			display: block; } } }

.reviews-icon {
	background: url(../images/reviews-icon-banner@2x.png) rgba(0,0,0,0.5) center center no-repeat;
	background-size: 20px;
	position: absolute;
	bottom: 75px;
	color: #FFF;
	right: 0px;
	padding: $margin $margin;
	float: right;
	font-weight: 600;
	text-align: center;
	@include fontSize(14px,14px);
	&:hover {
		cursor: pointer; }
	@include breakpoint($sub-tablet) {
		background: url(../images/reviews-icon-banner@2x.png) rgba(0,0,0,0.5) 87% center no-repeat;
		padding: 11px $margin;
		background-size: 24px;
		padding-right: 70px; }

	span {
		display: none;
		@include breakpoint($sub-tablet) {
			display: block; } } }

.map-content-w {
	width: 200px;
	h4 {
		@include fontSize(20px, 28px);
		margin-bottom: 0;
		margin-top: $margin/2; }
	h5 {
		@include fontSize(15px, 20px);
		font-weight: 300;
		margin-bottom: $margin/2; }
	p {
		@include fontSize(14px, 18px);
		a {
			color: $dark-grey;
			text-decoration: none; } }
	p.small {
		font-weight: 600;
		@include fontSize(13px, 16px);
		border: 1px solid $light-grey;
		padding: $margin/2;
		@include full;
		text-align: center;
		margin-bottom: $margin/2;
		color: $dark-grey; }
	a.btn {
		color: $almost-white;
		background: $green;
		@include full;
		text-transform: uppercase;
		font-weight: 800;
		text-decoration: none;
		text-align: center;
		padding: $margin/2 $margin;
		border: 1px solid $green;
		margin-bottom: $margin/2;
		&:hover {
			background: darken($green, 5%); } } }
.location-map {
	@include full;
	@include breakpoint($sub-tablet) {
		@include span(8); } }
.review-content {
	@include full;
	border-bottom: 1px solid $light-grey;
	padding: $margin 0;
	margin-bottom: $margin;
	@extend .content;

	.review-name {
		@include fontSize(14px,18px);
		@include full; } }
.review-content-popup {
	max-width: 400px;
	margin: auto;
	.review-content-popup-i {
		@include full;
		background: url(../images/quotes@2x.png) #ebebeb 20px 20px no-repeat;
		background-size: 20px;
		&:hover {
			cursor: pointer; }
		p {
			clear: both;
			width: 100%;
			float: left;
			margin-left: 0;
			margin-right: 0;
			padding: 0;
			margin: 0;
			color: $body-color;
			border: 0 !important;
			padding-top: 30px;
			padding-left: 60px;
			padding-right: 50px;
			padding-bottom: 20px;
			font-weight: 400;
			font-size: 15px;
			line-height: 20px; }
		.review-name {
			@include fontSize(14px,18px);
			@include full;
			padding-left: 60px;
			padding-right: 50px;
			font-weight: bold;
			padding-bottom: 30px;
			color: #e46d72; } } }

a.more-room-info {
	text-decoration: underline; }
tr.room-content {
	display: none; }
.room-content-internal p {
	font-weight: 400;
	margin-bottom: 0; }
.body-loading {
	height: 100vh;
	background: url('../images/gears.svg') #FFF center center no-repeat;
	position: absolute;
	top: 0;
	@include full;
	z-index: 1; }
.home-c-w {
	@include full;
	margin-bottom: $margin*2; }
.testimonials-w-w {
	border-top: 0;
	@include full;
	background: url('../images/quotes@2x.png') $light-grey 20px 20px no-repeat;
	background-size: 20px;
	margin-bottom: $margin;
	position: relative;
	a.next {
		background: url('../images/chevron-down@2x.png') $light-grey 0 0 no-repeat;
		background-size: 14px;
		width: 14px;
		height: 9px;
		float: left;
		position: absolute;
		top: 50%;
		right: $margin;
		z-index: 99; }
	a.prev {
		background: url('../images/chevron-up@2x.png') $light-grey 0 0 no-repeat;
		background-size: 14px;
		width: 14px;
		height: 9px;
		float: left;
		position: absolute;
		top: 40%;
		right: $margin;
		z-index: 99; }
	.testimonials-w {
		@include full;
		.testimonial {
			@include full;
			blockquote {
				@include full;
				color: $red;
				padding: 0;
				margin: 0;
				border: 0!important;
				padding-top: $margin*1.5;
				padding-left: $margin*3;
				padding-right: $margin*4;
				padding-bottom: $margin;
				font-weight: 600;
				@include fontSize(16px,20px);
				a {
					color: $red; } }


			.client {
				color: $dark-grey;
				@include full;
				padding-left: $margin*3;
				padding-bottom: $margin;
				@include fontSize(15px,18px); } } } }
