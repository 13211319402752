.archive-w {
	@include full;
	.archive {
		@include full;
		@include breakpoint($sub-tablet) {
			@include span(4 nobreak);
			&:nth-child(3n) {
				@include span(last 4 nobreak); } }

		.archive-hotel-image {}

		.archive-hotel-title {}

		.archive-hotel-location {
			a {} }

		.archive-hotel-from-price {}

		.archive-hotel-copy {} } }
