/* STANDARDS */
h1,h2,h3,h4,h5,h6, p, li, ul, ol {
  margin: 0;
  padding: 0; }

.content {

  h1,h2,h3,h4,h5,h6 {
    margin-bottom: 15px;
    font-family: $font;
    color: $heading-color;
    font-weight: 700;
    @include breakpoint($sub-desktop) {
      margin-bottom: 20px; }
    &.no-mb {
      margin-bottom: $margin/4; } }

  h1 {
    @include fontSize(24px,30px);
    @include breakpoint($sub-desktop) {
      @include fontSize($heading-size-1,$heading-line-1); } }

  h2 {
    @include fontSize($heading-size-3,$heading-line-3);
    @include breakpoint($sub-desktop) {
      @include fontSize($heading-size-2,$heading-line-2); } }

  h3 {
    @include fontSize($heading-size-4,$heading-line-4);
    @include breakpoint($sub-desktop) {
      @include fontSize($heading-size-3,$heading-line-3); } }
  h4 {
    @include fontSize(18px,24px);
    @include breakpoint($sub-desktop) {
      @include fontSize($heading-size-4,$heading-line-4); }
    color: $red; }
  h5 {
    @include fontSize($heading-size-5,$heading-line-5); }
  h6 {
    @include fontSize($heading-size-6,$heading-line-6); }

  p {
    margin: $paragraph-margin;
    @include fontSize(15px, 20px);
    color: $body-color;
    @include breakpoint($sub-tablet $sub-desktop) {
      @include fontSize(14px, 17px);
      margin-bottom: 15px; }
    &.alt, &.mtop {
       color: #939393!important;
       strong {
        font-weight: 400!important; } } }

  a {
    color: $anchor-color;
    text-decoration: $anchor-text-decoration;
    outline: 0;
    &:hover, &:focus {
      color: $anchor-color-hover;
      text-decoration: $anchor-text-decoration-hover; } }

  small {
    @include fontSize(13px,16px);
    margin-bottom: $margin;
    color: $body-color;
    @include full; }

  blockquote {
    @include fontSize(20px,30px);
    color: $body-color;
    margin: 0;
    margin-bottom: $margin;
    border-left: 6px solid $light-grey;
    padding: $margin/2 $margin;
    @include full;
    position: relative;
    @include breakpoint($sub-tablet);


    .top-left {
      background: url(../images/blockquote@2x.png) 0 0 no-repeat;
      background-size: 1000px;
      width: 21px;
      height: 21px;
      float: left;
      position: absolute;
      top: -1px;
      left: -1px; }
    .top-right {
      background: url(../images/blockquote@2x.png) right 0 no-repeat;
      background-size: 1000px;
      width: 21px;
      height: 21px;
      float: left;
      position: absolute;
      top: -1px;
      right: -1px; }
    .bottom-left {
      background: url(../images/blockquote@2x.png) 0 bottom no-repeat;
      background-size: 1000px;
      width: 21px;
      height: 21px;
      float: left;
      position: absolute;
      bottom: -1px;
      left: -1px; }
    .bottom-right {
      background: url(../images/blockquote@2x.png) right bottom no-repeat;
      background-size: 1000px;
      width: 21px;
      height: 21px;
      float: left;
      position: absolute;
      bottom: -1px;
      right: -1px; } }


  hr {
    border: 0;
    height: $hr-height;
    background: $hr-bg;
    margin: $margin 0;
    width: 100%;
    float: left;
    clear: both;
    &.half {
      margin: $margin 0; }
    @include breakpoint($sub-tablet) {
      margin: $hr-margin; } }


  abbr {
    border-bottom: $abbr-border-bottom;
    cursor: help; }

  figcaption {
    font-style: $figcaption-font-style;
    margin: $figcaption-margin; }


  /* IMAGES */

  img {
    &.align-right, &.alignright {
      float: right;
      margin: 0;
      margin-left: $margin;
      margin-bottom: $margin; }
    &.align-left, &.alignleft {
      float: left;
      margin: 0;
      margin-right: $margin;
      margin-bottom: $margin; }
    &.align-center, &.aligncenter {
      display: block;
      margin: 0 auto $margin auto; }

    &.off-right {
      width: 100%;
      float: right;
      clear: both;
      margin-right: -30px;
      margin-bottom: $margin*2;

      @include breakpoint($sub-tablet) {
        margin-left: 60px;
        width: 30%;
        clear: none; }
      @include breakpoint($sub-desktop) {
        margin-right: -60px;
        width: 500px; }
      @include breakpoint(1360px) {
        margin-right: -180px; } }

    &.off-left {
      width: 100%;
      float: left;
      clear: both;
      margin-left: -30px;
      margin-bottom: $margin*2;
      @include breakpoint($sub-tablet) {
        margin-right: 60px;
        width: 30%;
        clear: none; }
      @include breakpoint($sub-desktop) {
        margin-left: -60px;
        width: 500px; }
      @include breakpoint(1360px) {
        margin-left: -180px; } }

    &.full {
      float: left;
      width: 100%;
      clear: both;
      margin-bottom: $margin; } }


  /* TABLE */

  table {
    border-collapse: collapse;
    border-spacing: 0;
    border: $table-border;
    width: 100%;
    color: #FFFFFF; }

  th {
    text-align: left;
    border: $table-border;
    padding: $table-padding; }

  td {
    border: $table-border;
    padding: $table-padding;
    color: $dark-grey;
    font-weight: 600;
    &:nth-child(even) {
      color: $body-color;
      font-weight: 400; } }

  dl {
    overflow: hidden; }


  /* LISTS */

  .bullet-list {
    list-style: disc;
    @include fontSize(15px, 22px);
    @include breakpoint($sub-tablet $sub-desktop) {
      @include fontSize(14px, 17px); }
    li {
      @include fontSize(15px, 22px);
      @include breakpoint($sub-tablet $sub-desktop) {
        @include fontSize(14px, 17px); } } }

  .numbered-list {
    @extend .bullet-list;
    list-style: decimal; }

  ul {
    width: 100%;
    float: left;
    clear: both;
    padding: $lists-margin;
    li {
      @extend .bullet-list;
      width: 100%;
      float: left;
      clear: both;
      margin: $list-item-margin;
      color: $body-color;
      ol,ul {
        margin: $sub-lists-margin;
        padding-left: 0; }
      ol {
        li {
          @extend .numbered-list; } } } }
  ol {
    width: 100%;
    float: left;
    clear: both;
    padding: $lists-margin;

    li {
      width: 100%;
      float: left;
      color: $body-color;
      clear: both;
      @extend .numbered-list;
      margin: $list-item-margin;
      ol,ul {
        margin: $sub-lists-margin; }

      ul {
        li {
          @extend .bullet-list; } } } } }

pre {
  background: lighten(#edf2f0, 4%);
  padding: $pad;
  @include full;
  font-family: $font;
  font-style: italic;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  color: $body-color;
  @include fontSize(18px,30px);
  margin-bottom: $margin*2; }
