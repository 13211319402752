#mobileMenu {
  @include full;
  background: $deep-red;
  color: $almost-white;
  padding: $margin/2 $margin;
  text-align: center;
  @include boxShadow;

  @include breakpoint($sub-tablet) {
    display: none; } }

.header-spacer {
  @include full;
  height:  115px;
  @include breakpoint($sub-tablet) {
    height: 165px; }
  @include breakpoint(960px) {
     height:  130px; }
  @include breakpoint($sub-desktop) {
     height:  170px; } }
.header-w-w {
  position: fixed;
  z-index: 1000;
  @include full; }

.header-w {
  @include full;
  background: $light-grey;
  padding: 0 $margin;


  .header {
    padding: $margin 0;
    @extend .container;
    a.search-btn {
      background: url(../images/search-icon@2x.png) $dark-grey center center no-repeat;
      background-size: 16px;
      color: $almost-white;
      padding: $margin/2 $margin/1.5;
      float: right;

      @include fontSize(12px,14px);
      display: block;
      @include breakpoint(360px) {
        padding: $margin/2 $margin; }
      @include breakpoint($sub-tablet) {
        display: none; } }


    a.phone {
      background: url(../images/mobile-white@2x.png) $dark-grey center center no-repeat;
      background-size: 12px;
      padding: $margin/2 $margin/1.5;
      float: right;
      margin-right: 2px;

      @include fontSize(12px,14px);
      display: block;
      @include breakpoint(360px) {
        padding: $margin/2 $margin; }
      @include breakpoint($sub-tablet) {
        display: none; } }

    .logo {
      @include span(13 of 24 nobreak);
      @include breakpoint($sub-tablet $sub-desktop) {
        width: 45%;
        padding-top: 15px; }
      @include breakpoint($sub-desktop) {
        @include span(7 of 24 nobreak); }

      a {
        @include full;

        @include breakpoint(960px) {
          margin-top: 15px; }
        img {
          width: 180px;
          @include breakpoint($sub-tablet) {
            width: 280px; }
          @include breakpoint($sub-desktop) {
            width: 100%; } } } }

    .header-contact {
      @include span(last 6 nobreak);
      @include breakpoint($sub-desktop) {
        @include span(last 8 nobreak); }



      .header-contact-i {
        position: relative;
        height: 68px;
        float: right;
        width: 218px;
        margin-top: 10px;
        display: none;
        @include breakpoint($sub-tablet) {
          display: block; }
        @include breakpoint($sub-tablet $sub-desktop);

        ul.contact {
          float: right;

          li {
            float: left;
            @include full;

            &:nth-child(1) {
              @include fontSize(27px,26px);
              position: relative;
              text-align: right;
              border-bottom: 1px solid lighten($body-color,40%);
              padding-bottom: $margin/4;
              margin-bottom: $margin/4;

              &:before {
                content: '';
                background: url(../images/mobile@3x.png) 0 0 no-repeat;
                background-size: 15px;
                width: 20px;
                height: 22px;
                position: absolute;
                top: 2px;
                left: 0;
                float: left; } }
            a {
              color: $dark-grey;
              text-decoration: none; } } }


        .language-switch {
          position: absolute;
          bottom: 0;
          left: 0;
          border-top: 1px solid lighten($body-color,40%);


          @include full;
          span {
            @include full; }
          ul {
            float: left;
            padding-top: $margin/2;
            @include breakpoint($sub-tablet $sub-desktop) {
              padding-top: 5px; }
            li {
              margin-left: 0;
              @include fontSize(11px,14px);
              color: $body-color; }
            @include even() {
              float: right;
              li {
                float: left;
                margin-left: $margin/4;


                a {
                  background: url('../images/flags@2x.png') 0 0 no-repeat;
                  background-size: 68px;
                  width: 22px;
                  float: left;
                  height: 14px;
                  transition: all 0.5s;
                  cursor: pointer;
                  &:hover {
                    opacity: 0.5;
                    transition: all 0.5s; }

                  &.german {
                    background: url('../images/flags@2x.png') -23px 0 no-repeat;
                    background-size: 68px; }
                  &.swedish {
                    background: url('../images/flags@2x.png') -45px 0 no-repeat;
                    background-size: 68px; } } } } } } } } } }

.headline-w {
  background: $deep-red;
  padding: $margin/1.5 $margin;
  border-top: 1px solid darken($deep-red,5%);
  display: none;
  @include full;
  @include breakpoint($sub-tablet) {
    padding: $margin $margin;
    border-top: 0; }
  .headline {
    @extend .container;
    color: $almost-white;
    h1 {
      @include full;
      font-weight: 500;
      @include fontSize(18px,18px);
      @include breakpoint($sub-tablet) {
        @include fontSize(24px,24px); } } } }
