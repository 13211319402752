/*------------------------------------*\
    #PAGE
\*------------------------------------*/

/**
 * High-, page-level styling.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project,
 *    sourced from our default variables. The `font-size` is calculated to exist
 *    in ems, the `line-height` is calculated to exist unitlessly.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 * 4. Prevent certain mobile browsers from automatically zooming fonts.
 * 5. Fonts on OSX will look more consistent with other systems that do not
 *    render text using sub-pixel anti-aliasing.
 */
html {
	font-size: ($base-font-size / 16px) * 1em; /* [1] */
    line-height: $base-line-height / $base-font-size; /* [1] */
	background: $html-background;
	font-family: $font;
    overflow-y: scroll; /* [2] */
    min-height: 100%; /* [3] */
    -webkit-text-size-adjust: 100%; /* [4] */
    -ms-text-size-adjust: 100%; /* [4] */
    -moz-osx-font-smoothing: grayscale; /* [5] */
    -webkit-font-smoothing: antialiased; /* [5] */
}


/*------------------------------------*\
    #IMAGES
\*------------------------------------*/

/**
 * 1. Fluid images for responsive purposes.
 * 2. Offset `alt` text from surrounding copy.
 * 3. Setting `vertical-align` removes the whitespace that appears under `img`
 *    elements when they are dropped into a page as-is. Safer alternative to
 *    using `display: block;`.
 */
img {
    max-width: 100%; /* [1] */
    font-style: italic; /* [2] */
    vertical-align: middle; /* [3] */
}



/**
 * 1. Google Maps breaks if `max-width: 100%` acts upon it; use their selector
 *    to remove the effects.
 * 2. If a `width` and/or `height` attribute have been explicitly defined, let’s
 *    not make the image fluid.
 */
.gm-style img, /* [1] */
img[width], /* [2] */
img[height] {  /* [2] */
    max-width: none;
}

ol, ul {
    margin: 0;
    padding: 0;
    list-style: none;
}