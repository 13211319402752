fieldset {
	border: 0;
	padding: 0;
	margin: 0; }

.input-w {
	margin: $input-w-margin; }

label {
	display: block;
	font-weight: 600;
	margin: $label-margin; }


button, input, select, textarea {
	font-family: inherit;
	font-size: 100%;
	margin: 0; }

input[type="submit"].btn {
	background: $green;
	border: 1px solid $green;
	color: #FFF;
	text-transform: uppercase;
	padding-left: 30px;
	padding-right: 30px; }

input, textarea {
	width: 100%;
	-webkit-border-radius: $form-input-radius;
	-moz-border-radius: $form-input-radius;
	border-radius: $form-input-radius;
	background: $form-input-background;
	border: $form-input-border;
	padding: $form-input-padding;
	color: $form-input-color;
	outline: $form-input-outline;
	font-size: $form-font-size; }

select {
	height: 47px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	text-overflow: '';
	font-size: $form-font-size;
	-webkit-border-radius: 0px;

	width: 100%;
	color: $form-input-color;
	border: $form-input-border;
	padding: $form-input-padding;

	background: $form-input-background;
	&#region {
		font-size: 14px;
		@include breakpoint($sub-desktop) {
			font-size: $form-font-size; } }


	&::-ms-expand {
		display: none; } }
input.typeahead {
	font-size: 14px;
	@include breakpoint($sub-desktop) {
		font-size: $form-font-size; } }
.select-w {
	@include full;
	position: relative;
	&:after {
		top: 50%;
		right: $margin;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-color: rgba(0, 0, 0, 0);
		border-top-color: $select-chevron;
		border-width: 8px;
		margin-top: -4px; } }

input[type=text], input[type=search], input[type=url], input[type=number], textarea {
	-webkit-appearance: none; }

input[type="color"] {
	width: inherit; }


input[type="submit"].btn {
	width: inherit; }

input[type="checkbox"],
input[type="radio"] {
	width: auto; }

input[type="search"] {
	-webkit-appearance: none;
	border-radius: $form-input-radius; }


input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none; }

/* Validation */
.has-error, .is-error {
	border-color: $form-input-error; }

.is-valid {
	border-color: $form-input-success; }


textarea,
input[type="text"],
input[type="email"],
input[type="button"],
input[type="submit"] {
	-webkit-appearance: none;
	border-radius: $form-input-radius; }

::-webkit-input-placeholder, :-moz-placeholder, ::-moz-placeholder, :-ms-input-placeholder {
	color: #000; }
input::-webkit-input-placeholder {
	color: #000; }
.icheckbox_square, .iradio_square, .custom-radio, .custom-checkbox {
	background: $checkbox-background;
	width: 20px;
	height: 20px;
	border: $checkbox-border;
	margin-top: 2px;
	margin-right: 8px;
	float: left;
	&.hover {
		background: $checkbox-background;
		border: $checkbox-border-hover; }
	&.checked {
		background: $checkbox-background-check;
		border: $checkbox-border-hover; } }


.iradio_square, .custom-radio {
	@include br($icheck-radio-radius);
	&.hover {
		background: $checkbox-background;
		border: $checkbox-border-hover; }
	&.checked {
		background: $checkbox-background-check;
		border: $checkbox-border-hover; } }

.seasons-controls {
	position: relative;
	a {
		width: 20px;
		height: 20px;
		&.pull-left {
			float: left;
			position: relative;
			&:after {
				right: 100%;
				top: 50%;
				border: solid transparent;
				content: " ";
				height: 0;
				width: 0;
				position: absolute;
				pointer-events: none;
				border-color: rgba(51, 51, 51, 0);
				border-right-color: #333;
				border-width: 8px;
				margin-top: -8px; } }
		&.pull-right {
			float: right;
			position: relative;
			&:after {
				left: 50%;
				top: 50%;
				border: solid transparent;
				content: " ";
				height: 0;
				width: 0;
				position: absolute;
				pointer-events: none;
				border-color: rgba(51, 51, 51, 0);
				border-left-color: #333;
				border-width: 8px;
				margin-top: -8px; } } } }
.form-w {
	margin: $margin*2 0;
	@include full;
	button.btn {
		background: $green;
		color: $almost-white;
		@include full;
		border: 0;
		padding: $margin;
		cursor: pointer;
		margin-top: $margin; }
	.form-row-w {
		@include full;


		.col {
			margin-bottom: $margin; }
		&.colx2 {
			.col {
				@include full;
				@include breakpoint($sub-tablet) {
					@include span(23 of 48 nobreak);
					&:nth-child(even) {
						@include span(last 23 of 48 nobreak); } } } }
		.col {
			&.colx2 {
				.input-w {
					@include full;
					&:nth-child(even) {
						label {
							display: none; } }
					@include breakpoint($sub-tablet) {
						@include span(22 of 48 nobreak);
						&:nth-child(even) {
							@include span(last 22 of 48 nobreak);
							label {
								display: block; } } } } } } } }
#all_rooms_box {
	display: none; }
.booking-table {
	@include full;
	margin-top: $margin;
	table {
		border: 1px solid $red!important;

		th {
			background: $red;

			border: 0; }
		tr {
			background: #eee;
			border: 0;
			&:nth-child(even) {
				background: #FFF; }
			td {
				border: 0; } } } }
table.booking {
	border: 1px solid $red!important;

	th {
		background: $red;

		border: 0; }
	tr {
		background: #eee;
		border: 0;
		&:nth-child(even) {
			background: #FFF; }
		td {
			border: 0; } } }
