.banner-w {
	@include full;
	background: $almost-white;
	@include breakpoint($sub-desktop) {
		border-top: $margin solid $almost-white; }
	&.single-hotel {
		.banner-slideshow-i-img, .banner-slideshow-i {
			height: 150px!important;
			@include breakpoint($sub-desktop) {
				height: 240px!important; }
			@include breakpoint($sub-tablet) {
				height: 317px!important; } } }
	.banner {
		@extend .container;
		background: $light-grey;

		.thumbnails {
			.thumbnails-title {
				color: $dark-grey;
				font-weight: 800;
				@include fontSize(18px,20px);
				text-transform: uppercase;
				margin-top: $margin/2;
				margin-bottom: $margin/2; }
			.thumbnails-w {
				height: 230px;
				overflow: scroll; }
			@include full;
			background: $light-grey;
			display: none;
			padding: $margin;
			padding-right: $margin*2;
			@include breakpoint($sub-tablet) {
				@include span(last 4 nobreak);
				display: block; }
			.thumbnail-img {
				@include span(2 of 8);
				height: 50px;
				margin-bottom: $margin/2;

				&:nth-child(4n) {
					@include span(last 2 of 8); } } }
		.search {
			@include full;
			background: $light-grey;
			display: none;
			@include breakpoint($sub-tablet) {
				@include span(last 4 nobreak);
				display: block; }


			.form {
				@include full;
				padding: $margin*2 $margin;
				padding-right: $margin*2;

				.search-title {
					color: $dark-grey;
					font-weight: 800;
					@include fontSize(18px,20px);
					text-transform: uppercase;
					margin-bottom: $margin/2; }

				.map {
					margin-top: $margin/2;
					padding: $margin;
					color: $almost-white;
					text-transform: uppercase;
					background: url(../images/mallorca-map@2x.png) $red 90% center no-repeat;
					background-size: 70px;
					@include fontSize(14px,16px);
					@include full;
					transition: all 0.5s;
					@include breakpoint($sub-tablet $sub-desktop) {
						display: none; }
					&:hover {
						cursor: pointer;
						background: url(../images/mallorca-map@2x.png) darken($red,5%) 90% center no-repeat;
						background-size: 70px;
						transition: all 0.5s; }

					.col {
						@include span(8);
						&:nth-child(even) {
							@include span(last 4); } } } } }



		.banner-slideshow {

			@include full;


			.banner-slideshow-i {
				@include full;
				position: relative;
				height: 240px;
				@include breakpoint($sub-tablet) {
					height: 340px; }
				.banner-slideshow-i-title {
					background: rgba(0,0,0,0.5);
					color: $almost-white;
					position: absolute;
					bottom: $margin;
					left: 0;
					@include fontSize(20px,20px);
					padding: 15px $margin;
					float: left;
					@include breakpoint($sub-tablet) {
						padding: $margin/2 $margin; }

					h1 {
						@include fontSize(14px,14px);
						span {
							@include fontSize(14px,14px); }

						@include breakpoint($sub-tablet) {
							@include fontSize(20px,20px);
							span {
								@include fontSize(20px,20px); } }

						&.home {
							@include fontSize(24px,24px);
							span {
								@include fontSize(30px,40px);
								clear: both;
								text-transform: uppercase; } } } }







				.banner-slideshow-i-img {
					@include full;

					height: 240px;
					@include breakpoint($sub-tablet) {
						height: 340px; } } } } } }
#archiveSlider {
	display: block;
	&.loading {
		display: none; } }

