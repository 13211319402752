.mobile-button {
	@include full;
	margin-top: -$margin;
	margin-bottom: $margin;
	display: block;
	@include breakpoint($sub-tablet) {
		display: none; }
	a.btn {
		color: $almost-white;
		background: $green;
		@include full;
		font-weight: 800;
		text-decoration: none;
		text-align: center;
		padding: $margin/2 $margin;
		border: 1px solid $green;
		&:hover {
			background: darken($green, 5%); } } }
.single-hotel-w-w {
	@include full;
	position: relative;
	.scroll-right {
		position: absolute;
		top: 0px;
		right: -20px;
		background: url(../images/scroll-right@2x.png) $almost-white center center no-repeat;
		padding: $margin/2 $margin/1.5;
		background-size: 8px;
		float: left;
		z-index: 100;
		border: 1px solid $light-grey;
		display: none; } }
.single-hotel-w {
	@include full;
	position: relative; }


.single-hotel-navigation {
	overflow: hidden;
	position: relative;


	ul {
		//display: flex
		//overflow: scroll
		//flex-direction: row
		margin: 0;
		padding: 0;
		margin-bottom: $margin*1.5;
		@include breakpoint(960px) {
			display: flex;
			overflow: scroll;
			flex-direction: row; }
		@include breakpoint($desktop) {
			overflow: hidden; }
		li {
			list-style: none;
			//flex-grow: 1
			text-align: center;
			width: 33.333333%;
			margin-bottom: 0;
			clear: none;
			font-size: 11px;
			@include breakpoint(500px) {
				font-size: 14px; }
			&:nth-child(4), &:nth-child(5) {
				width: 50%; }
			&:nth-child(4) {
				border-left: 1px solid $light-grey; }

			@include breakpoint(960px) {
				flex-grow: 1;
				width: 100%;
				&:nth-child(4), &:nth-child(5) {
					width: 100%; }
				&:nth-child(4) {
					border-left: 0; } }

			&#overview {


				a {
					span {
						position: relative;
						padding-left: 22px;
						&:after {
							position: absolute;
							left: 0px;
							content: '';
							width: 16px;
							height: 16px;
							margin-top: 5px;
							float: left;
							background: url(../images/overview-icon@2x.png) 0 0 no-repeat;
							background-size: 16px; } } } }

			&#rates {

				a {
					span {
						position: relative;
						padding-left: 20px;
						&:after {
							position: absolute;
							left: 0px;
							content: '';
							width: 16px;
							height: 16px;
							margin-top: 5px;
							float: left;
							left: 0px;

							background: url(../images/rates-icon@2x.png) 0 0 no-repeat;
							background-size: 13px; } } } }

			&#location {
				a {
					span {
						position: relative;
						padding-left: 20px;
						&:after {
							position: absolute;
							left: 0px;
							content: '';
							width: 16px;
							height: 16px;
							margin-top: 3px;
							float: left;
							background: url(../images/location-icon@2x.png) 0 0 no-repeat;
							background-size: 11px; } } } }
			&#reviews {
				a {
					span {
						position: relative;
						padding-left: 24px;
						&:after {
							position: absolute;
							left: 0px;
							content: '';
							width: 20px;
							height: 16px;
							margin-top: 5px;
							float: left;
							background: url(../images/reviews-icon@2x.png) 0 0 no-repeat;
							background-size: 17px; } } } }
			&#policies {
				a {
					span {
						position: relative;
						padding-left: 22px;
						&:after {
							position: absolute;
							left: 0px;
							content: '';
							width: 16px;
							height: 16px;
							margin-top: 5px;
							float: left;
							background: url(../images/policies-icon@2x.png) 0 0 no-repeat;
							background-size: 14px; } } } }

			&:nth-child(1) {
				border-left: 1px solid $light-grey; }
			&:last-child() {}

			&.current a {
				color: $dark-grey;
				border-bottom: 0;
				font-weight: 600;
				border-bottom: 1px solid $light-grey;
				@include breakpoint(960px) {
					border-bottom: 0; } }

			a {
				padding: $margin/2 $margin/2;
				text-decoration: none;
				text-align: center;
				color: $body-color;
				display: BLOCK;

				border: 1px solid $light-grey;
				border-left: 0;
				&:hover {
					color: $dark-grey;
					background: lighten($light-grey,5%);
					font-weight: 600; } } } } }

.hotel-title {
	@include full;
	position: relative;

	h1 {
		span {
			@include fontSize(16px,22px); }
		a {}
		@include breakpoint($sub-tablet $sub-desktop) {
			width: 75%;
			height: 35px; } }

	.single-star-w {
		@include full;
		position: relative;
		margin-bottom: 20px;
		@include breakpoint($sub-tablet) {
			position: absolute;
			top: -6px;
			right: 0; }
		@include breakpoint($sub-desktop) {
			top: 0; }
		.hotel-map {
			padding: 20px 20px;
			border: 1px solid #ebebeb;
			float: left;
			background: url(../images/location@2x.png) center center no-repeat;
			background-size: 12px;
			//position: absolute
			top: 0;
			right: 50px;
			width: 40px;
			height: 40px;
			cursor: pointer;
			@include breakpoint($sub-tablet) {
				position: absolute; }

			&:hover {
				background: url(../images/location-roll@2x.png) center center no-repeat;
				background-size: 12px;
				cursor: pointer; } }
		.star {
			padding: 20px 20px;
			border: 1px solid #ebebeb;
			float: left;
			background: url(../images/favourite@2x.png) center center no-repeat;
			background-size: 16px;
			//position: absolute
			top: 0;

			margin-right: 10px;
			right: 0;
			width: 40px;
			height: 40px;
			cursor: pointer;
			@include breakpoint($sub-tablet) {
				position: absolute;
				margin-right: 0; }
			&:hover, &.favourite {
				background: url(../images/favourite-roll@2x.png) center center no-repeat;
				background-size: 16px;
				cursor: pointer; } } } }
ul.direct-rooms {
	@include full;
	margin-top: $pad;
	li {
		@include full;
		border: 1px solid $border-color;
		padding: $pad;
		border-bottom: 0;
		&:last-child() {
			border-bottom: 1px solid $border-color; }
		.room-title {
			@include span(8);
			position: relative;
			height: 42px;
			span {
				position: absolute;
				left: 0;
				top: 50%;
				transform: translate(0,-50%); } }
		.room-check-availability {
			@include span(last 4);
			a {
				color: #fff;
				background: #1ebf86;
				clear: both;
				width: 100%;
				float: left;
				margin-left: 0;
				margin-right: 0;
				text-transform: uppercase;
				font-weight: 800;
				text-decoration: none;
				text-align: center;
				padding: 10px 20px;
				border: 1px solid #1ebf86; } } } }
