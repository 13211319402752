@charset "utf-8";
/* SETTINGS */
@import "settings.sass";

/* FRAMEWORK */
@import "framework/normalize/normalize";
@import "framework/normalize/box-sizing";
@import "framework/normalize/html";
@import "framework/layout/susy/language/susy";
@import "framework/layout/breakpoint";
@import "framework/base/mixins";
@import "framework/base/family";
@import "framework/base/content";
@import "framework/base/form";
@import "framework/plugins/animista";
@import "framework/plugins/magnific";
@import "framework/plugins/datepicker";
@import "framework/plugins/dop";

.container {
	@include container($desktop); }

@import "archive";
@import "banner";
@import "home-banner";
@import "hotel";
@import "body";
@import "footer";
@import "header";
@import "navigation";
@import "sub-footer";
@import "modal";
@import "tablet";
