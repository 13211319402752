@include breakpoint(700px 1025px) {
    .body-w .body {
        padding: 20px 0;
        .sidebar-w {
            width: 33%!important; }

        .main-w {
            width: 64.5%;
            .blog-w {
                width: 100%;

                a.blog {
                    padding-bottom: 10px;
                    .blog-content h5 {
                        margin-bottom: 0; }

                    .blog-image {
                        margin-bottom: 10px; } } }

            &.home {
                width: 63%; } }

        .sidebar {
            padding: 10px 20px;

            &.red-title .sidebar-title {
                margin-bottom: 20px; }

            &.mb {
                margin-bottom: 10px; }

            &.button {
                margin-bottom: 10px;
                a.send {
                    padding-left: 0;
                    padding-right: 0;
                    font-size: 13px;
                    padding-top: 18px; } }

            &.title-border-bottom {
                margin-bottom: 20px; }

            label {
                font-size: 12px;
                line-height: 23px;
                margin-bottom: 5px; }

            ul.filter-list {
                padding-top: 10px; }

            .sidebar-title {
                font-size: 13px;
                margin-bottom: 10px; }

            .sidebar-content ul {
                padding-bottom: 0;
                &.list {
                    padding-bottom: 10px; }

                li {
                    margin-bottom: 0; } }

            .why-book ul li {
                padding: 10px 0;
                padding-left: 60px;

                &.insider-knowledge:before {
                    background: url(https://ruralhotelsmallorca.com/images/insider-knowledge@2x.png) 36% 40% no-repeat;
                    background-size: 33px; }
                &.unique-retreats:before {
                    background: url(https://ruralhotelsmallorca.com/images/unique-retreats@2x.png) 32% 40% no-repeat;
                    background-size: 25px; }

                &.best-rates:before {
                    background: url(https://ruralhotelsmallorca.com/images/best-rates@2x.png)  30% 40% no-repeat;
                    background-size: 33px; }

                &.flexibility:before {
                    background: url(https://ruralhotelsmallorca.com/images/flexibility@2x.png) 40% 40% no-repeat;
                    background-size: 35px; }

                p {
                    font-size: 13px; } } } }


    .hotels-w .hotel .hotel-controls .hotel-price span {
        font-size: 13px; }

    .home-c-w {
        margin-bottom: 5px;
        ul {
            padding-bottom: 10px!important; } }

    .header-w .header {
        .header-contact .header-contact-i ul.contact li {
            font-size: 12px;
            &:nth-child(1) {
                font-size: 22px; } }

        .logo a img {
            width: 240px; }

        .header-contact-i {
            height: 79px;

            .language-switch {
                width: 86%;
                right: 0;
                left: auto;
                ul:nth-child(1) {
                    display: none; } } } }


    .header-spacer {
        height: 160px; }

    .content h1 {
        font-weight: 1000; }


    .banner-w .banner .banner-slideshow .banner-slideshow-i .banner-slideshow-i-img {
        height: 220px; }

    input.typeahead {
        font-size: 14px;
        height: 44px; }

    .testimonials-w-w .testimonials-w .testimonial blockquote {
        font-size: 14px; }


    .hotel-title {
        .single-star-w {
            top: 0;

            .star, .hotel-map {
                padding: 21px 10px;
                border: 0; } }

        h1 {
            height: 45px;
            line-height: 45px; } }

    .call-henrik-sidebar h3 {
        font-size: 10px;
        line-height: 14px; }

    .navigation-w .navigation ul li a {
        padding-left: 10px;
        padding-right: 10px; }

    .single-hotel-navigation ul li {
        width: 20%;
        &:nth-child(4), &:nth-child(5) {
            width: 20%; }

        &#overview a span, &#rates a span, &#reviews a span, &#policies a span {
            padding-left: 0; }

        a {
            padding-top: 30px;

            span:after {
                left: 50%!important;
                margin-left: -8px!important;
                top: -22px!important; } } }

    .review-content {
        padding-top: 0px; }

    .banner-w .banner .search .form {
        padding: 40px 20px;
        padding-left: 10px; }

    .reviews-icon {
        padding-top: 6px;
        padding-bottom: 6px;
        padding-right: 50px;
        font-size: 11px;
        background-size: 22px;
        bottom: 65px; }

    .gallery-icon {
        padding-top: 6px;
        padding-bottom: 6px;
        padding-right: 50px;
        font-size: 11px;
        background-size: 26px; }

    td.rate-p {
        padding: 10px 0; }

    #contact-form, #enquiry-form {
        width: 95%; }

    .input-w {
        margin-bottom: 10px; }

    .input-w label {
        margin-bottom: 5px; }

    .slide {
        font-size: 12px; }

    .content td {
        line-height: 16px;
        font-size: 12px; } }


@include breakpoint(min-width 1025px) {

    .popup-form {
        padding: 20px;

        .input-w {
            margin-bottom: 10px!important;

            input, textarea, label {
                font-size: 13px; } } }

    .banner-slideshow-i-title h1 a {
        color: #FFFFFF;
        text-decoration: none; } }

