.home-banner-w {
	@include full;
	background: #FFFFFF;
	position: relative;

	@include breakpoint($sub-tablet) {
		padding: 0 $margin; }
	@include breakpoint($desktop) {
		margin-top: 0;
		@include container($desktop);
		float: none; }

	.home-banner {
		@extend .container;
		.bx-viewport {

			@include breakpoint($sub-tablet) {
				position: absolute!important;
				left: 0;
				top: 0; } }


		.search {
			@include full;
			position: relative;
			z-index: 99;
			display: none;
			@include breakpoint($sub-tablet) {
				@include span(last 19 of 48 nobreak);
				display: block; }
			@include breakpoint($sub-desktop) {
				@include span(last 4 nobreak);
				display: block; }


			.home-form {
				@include full;
				padding: $margin*2 $margin;
				padding-right: 0;
				@include breakpoint($sub-tablet $sub-desktop) {
					padding-bottom: 20px; }

				.home-search-title {
					color: $almost-white;
					font-weight: 800;
					@include fontSize(18px,20px);
					text-transform: uppercase;
					margin-bottom: $margin/2;
					//visibility: hidden
					display: none;
					//@include breakpoint($sub-tablet $sub-desktop)
					padding: 10px;
					float: left;
					background: rgba($dark-grey,0.7); }

				.home-map {
					margin-top: $margin/2;
					padding: $margin;
					color: $almost-white;
					text-transform: uppercase;
					background: url(../images/mallorca-map@2x.png) $red 90% center no-repeat;
					background-size: 70px;
					@include fontSize(14px,16px);
					@include full;
					transition: all 0.5s;

					@include breakpoint($sub-tablet $sub-desktop) {
						display: none; }
					&:hover {
						cursor: pointer;
						background: url(../images/mallorca-map@2x.png) darken($red,5%) 90% center no-repeat;
						background-size: 70px;
						transition: all 0.5s; }

					.col {
						@include span(8);
						&:nth-child(even) {
							@include span(last 4); } } } } }



		.home-banner-slideshow {

			@include full;
			@include breakpoint($sub-tablet) {
				@include span(8 nobreak); }

			.home-banner-slideshow-i {
				@include full;
				height: 150px;


				.home-banner-slideshow-i-title {
					color: $almost-white;
					position: absolute;
					float: left;
					z-index: 10;
					font-weight: 400;
					bottom: $margin;
					@include fontSize(20px,26px);
					padding: 0 $margin;
					span {
						@include full;
						font-weight: 800;
						@include fontSize(28px,28px); }
					@include breakpoint($sub-tablet) {
						bottom: $margin*2;
						left: 0;
						margin-left: 0;
						@include fontSize(30px,36px);
						span {
							@include fontSize(34px,34px); } }
					@include breakpoint($sub-tablet $sub-desktop) {
						bottom: 0;
						left: 0;
						margin-left: 0; }
					@include breakpoint($sub-desktop) {
						bottom: $margin*2;
						left: 0;
						padding: 0;
						margin-left: 0;
						@include fontSize(40px,46px);

						span {

							@include fontSize(48px,48px); } } }

				.home-banner-slideshow-i-title-i {
					background: rgba(#333,0.7);
					padding: 20px; }


				.home-banner-slideshow-i-img {
					@include full;
					position: absolute;
					top: 0;
					left: 0;
					height: 150px;
					&.slide-1 {
						background: url('../images/slider@2x.jpg') top center no-repeat;
						background-size: cover;
						@include breakpoint($sub-desktop) {
							background: url('../images/slider@2x.jpg') center center no-repeat;
							background-size: cover; } }
					&.slide-2 {
						background: url('../images/slider-2@2x.jpg') top center no-repeat;
						background-size: cover;
						@include breakpoint($sub-desktop) {
							background: url('../images/slider-2@2x.jpg') center center no-repeat;
							background-size: cover; } }
					&.slide-3 {
						background: url('../images/slider-3@2x.jpg') top center no-repeat;
						background-size: cover;
						@include breakpoint($sub-desktop) {
							background: url('../images/slider-3@2x.jpg') center center no-repeat;
							background-size: cover; } } } } } } }

.home-types-w {
	@include full;
	background: $almost-white;
	padding: 0 $margin;
	padding-top: $margin;
	display: none;
	@include breakpoint($sub-tablet) {
		display: block; }
	.home-types {
		@extend .container;

		a.home-type {
			display: flex;
			align-items: center;
			justify-content: center;

			color: $almost-white;
			background: #767475;
			text-align: center;
			padding: $margin $margin*2;
			height: 130px;
			font-weight: 800;
			margin-bottom: $margin;
			@include fontSize(16px, 20px);
			@include span(2 of 4);
			cursor: pointer;
			&:hover {
				background: $dark-grey!important; }
			@include even() {
				@include span(last 2 of 4); }
			@include breakpoint($sub-tablet) {
				margin-bottom: 0;
				@include span(2 of 10 nobreak);
				@include even() {
					@include span(2 of 10 nobreak); }
				&:last-child {
					@include span(last 2 of 10 nobreak); } } } } }

