.room-availability {
  height: 400px;
  background: #eaeaec;
  clear: both;
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0;
  margin-top: 9px;
  margin-top: 0.55rem; }

.DOP_BackendBookingCalendar_Container {
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: relative;
  background: #eaeaec; }

/* Navigation */
.DOP_BackendBookingCalendar_Navigation {
  margin: 0 -3px 0 5px;
  padding: 0;
  z-index: 200;
  position: relative; }

.DOP_BackendBookingCalendar_Navigation .previous_btn {
  margin: 0;
  padding: 0;
  position: absolute;
  background-color: #4c4c4c;
  z-index: 100;
  width: 20px;
  height: 20px;
  top: 10px;
  left: 0;
  cursor: pointer;
  opacity: 0.8;
  filter: alpha(opacity=80); }

.DOP_BackendBookingCalendar_Navigation .previous_btn:hover {
  opacity: 1;
  filter: alpha(opacity=100); }

.DOP_BackendBookingCalendar_Navigation .previous_btn.disabled {
  cursor: default; }

.DOP_BackendBookingCalendar_Navigation .previous_btn.disabled:hover {
  opacity: 0.8;
  filter: alpha(opacity=80); }

.DOP_BackendBookingCalendar_Navigation .previous_btn .icon {
  margin: 4px 0 0 3px;
  padding: 0;
  border-color: #4c4c4c #bcbcbc #4c4c4c #4c4c4c;
  border-style: solid;
  border-width: 6px 7px 6px 3px;
  width: 0px;
  height: 0px; }

.DOP_BackendBookingCalendar_Navigation .next_btn {
  margin: 0;
  padding: 0;
  z-index: 200;
  position: absolute;
  background-color: #4c4c4c;
  z-index: 200;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
  cursor: pointer;
  opacity: 0.8;
  filter: alpha(opacity=80); }

.DOP_BackendBookingCalendar_Navigation .next_btn:hover {
  opacity: 1;
  filter: alpha(opacity=100); }

.DOP_BackendBookingCalendar_Navigation .next_btn .icon {
  margin: 4px 0 0 7px;
  padding: 0;
  border-color: #4c4c4c #4c4c4c #4c4c4c #bcbcbc;
  border-style: solid;
  border-width: 6px 3px 6px 7px;
  width: 0px;
  height: 0px; }

.DOP_BackendBookingCalendar_Navigation .month_year {
  margin: 0;
  padding: 10px;
  font-weight: bold;
  font-size: 16px;
  text-align: center; }

.DOP_BackendBookingCalendar_Navigation .week {
  margin: 0;
  padding: 0;
  display: none; }

.DOP_BackendBookingCalendar_Navigation .week .day {
  margin: 5px 5px 5px 4px;
  padding: 0;
  text-align: center;
  float: left;
  display: block; }

/* Calendar */
.DOP_BackendBookingCalendar_Calendar {
  margin: 0;
  padding: 0;
  z-index: 300;
  position: relative; }

.DOP_BackendBookingCalendar_Month {
  margin: 0;
  padding: 0; }

.DOP_BackendBookingCalendar_Day {
  margin: 5px;
  padding: 0;
  float: left; }
  @media (max-width: 500px) {
    .DOP_BackendBookingCalendar_Day {
      margin-right: 1px;
      margin-left: 1px;
      font-size: 8px; } }

.DOP_BackendBookingCalendar_Day.last_month {
  cursor: pointer;
  opacity: 0.4;
  filter: alpha(opacity=40); }

.DOP_BackendBookingCalendar_Day.past_day {
  opacity: 0.20;
  filter: alpha(opacity=20); }

.DOP_BackendBookingCalendar_Day.curr_month {
  cursor: pointer; }

.DOP_BackendBookingCalendar_Day.next_month {
  cursor: pointer;
  opacity: 0.4;
  filter: alpha(opacity=40); }

.DOP_BackendBookingCalendar_Day .header {
  margin: 0;
  padding: 2px 5px 2px 5px;
  display: block;
  border: 1px solid #dddddd;
  background: #ffffff;
  overflow: hidden;
  height: 45px; }

.DOP_BackendBookingCalendar_Day .header .day {
  margin: 0;
  padding: 0;
  color: #777777;
  font-weight: bold;
  float: left; }

.avaliable .DOP_BackendBookingCalendar_Day .header .day {
  color: #ffffff; }

.DOP_BackendBookingCalendar_Day .header .price {
  margin: 0;
  padding: 0;
  color: #ffffff;
  float: left;
  width: 100%; }
  @media (max-width: 500px) {
    .DOP_BackendBookingCalendar_Day .header .price {
      font-size: 7px !important; } }

.date-content {
  height: 10px;
  background: none;
  margin-top: -25px;
  text-align: center;
  width: 100%;
  color: black;
  float: right;
  display: none; }

.DOP_BackendBookingCalendar_Day span.stay {
  display: none; }

.DOP_BackendBookingCalendar_Day.available span.stay {
  margin: -2px -6px -3px 10px;
  color: #fefffe;
  float: right;
  position: relative;
  left: 0;
  bottom: 0;
  display: block;
  background-color: #178017;
  padding: 4px 6px 4px 8px;
  display: none; }

.DOP_BackendBookingCalendar_Day .content {
  margin: 0;
  padding: 0 5px 0 5px;
  display: block;
  color: #222222;
  text-align: center;
  border: 1px solid #222222;
  border-top: none;
  background-color: #ededea; }

.DOP_BackendBookingCalendar_Day.available .header {
  background: #00c085; }

.DOP_BackendBookingCalendar_Day.booked span.price {
  display: none; }

.DOP_BackendBookingCalendar_Day.booked .header {
  border-color: #ff2e94;
  background-color: #ffc7e2; }

.DOP_BackendBookingCalendar_Day.booked .content {
  border-color: #ff2e94;
  background-color: #ffc7e2; }

.DOP_BackendBookingCalendar_Day.selected .header {
  background: #00a773;
  border-color: #005a3e; }

.DOP_BackendBookingCalendar_Day.selected .content {
  background: #00a773;
  border-color: #005a3e; }

/* Pop-Up */
.DOP_BackendBookingCalendar_PopUp {
  z-index: 400;
  display: none;
  position: absolute; }

.DOP_BackendBookingCalendar_PopUp .bg {
  position: absolute;
  opacity: 0.8;
  filter: alpha(opacity=80);
  background-color: #ffffff; }

.DOP_BackendBookingCalendar_PopUp .window {
  position: absolute;
  width: 100% !important;
  margin: 0px !important;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-bottom: 2px solid #dddddd; }
  .DOP_BackendBookingCalendar_PopUp .window .PopUp-inner {
    padding: 15px; }
    .DOP_BackendBookingCalendar_PopUp .window .PopUp-inner hr {
      margin-top: 9px;
      margin-top: 0.55rem;
      margin-bottom: 9px;
      margin-bottom: 0.55rem; }
  .DOP_BackendBookingCalendar_PopUp .window h5 {
    font-size: 20px;
    margin-bottom: 0 !important; }
  .DOP_BackendBookingCalendar_PopUp .window .start {
    width: 49.15254%;
    float: left;
    margin-right: 1.69492%; }
  .DOP_BackendBookingCalendar_PopUp .window .end {
    width: 49.15254%;
    float: right;
    margin-right: 0; }

.DOP_BackendBookingCalendar_PopUp .window .start-date {
  color: #777777;
  display: block;
  clear: both;
  width: 100%;
  float: left;
  font-size: 20px;
  margin-left: 0;
  margin-right: 0;
  float: left; }
.btn.pull-right {
  float: right;
  width: auto;
}
.btn {
  width: auto;
  color: #fff;
  background: #1ebf86;
  margin-left: 0;
  margin-right: 0;
  text-transform: uppercase;
  font-weight: 800;
  text-decoration: none;
  text-align: center;
  padding: 10px 20px;
  border: 1px solid #1ebf86;
}
span.buttons_container input[type=button]:disabled {
  opacity: 0.5;
}

.DOP_BackendBookingCalendar_PopUp .window .end-date {
  color: #777777;
  clear: both;
  width: 100%;
  font-size: 20px;
  float: left;
  margin-left: 0;
  margin-right: 0;
  display: block;
  float: right; }

.DOP_BackendBookingCalendar_PopUp .window .label {
  color: #eeeeee;
  font-size: 12px;
  font-weight: bold;
  margin: 10px 20px 0 20px;
  display: block; }

.DOP_BackendBookingCalendar_PopUp .window .label:first-child {
  margin-top: 20px; }

.DOP_BackendBookingCalendar_PopUp .window .select-style {
  color: #555555;
  background: #eeeeee;
  font-size: 12px;
  margin: 0 0 0 20px;
  border: none;
  width: 260px;
  padding: 2px 5px 2px 5px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.DOP_BackendBookingCalendar_PopUp .window .input-style {
  color: #555555;
  background: #eeeeee;
  font-size: 12px;
  margin: 0 0 0 20px;
  border: none;
  width: 250px;
  padding: 2px 5px 2px 5px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.DOP_BackendBookingCalendar_PopUp .window .buttons_container {
  clear: both;
  width: 100%;
  float: left;
  margin-bottom: 20px;
  margin-left: 0;
  margin-right: 0; }


#pricing-table {
  @extend .content;
  table {
    margin-top: 20px;
    margin-bottom: 20px;
    float: left;
    width: 100%;
    clear: both;
    td {
      font-size: 16px;
    }
    td.small-rates p {
        margin: 0!important;
    }
  }

}
.DOP_BackendBookingCalendar_PopUp .window .button-style {
  color: #eeeeee;
  font-size: 14px;
  font-weight: bold;
  margin: 20px 0 20px 10px;
  border: 1px solid #606060;
  background: #444444;
  padding: 3px 5px 3px 5px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  cursor: pointer; }

.DOP_BackendBookingCalendar_PopUp .window .button-style:hover {
  color: #eeeeee;
  border: 1px solid #a6a47e; }

.DOP_BackendBookingCalendar_PopUp .window .loader {
  width: 20px;
  height: 22px;
  margin: 20px 45px 20px 0;
  background: url("/admin/images/extranet/loader-small.gif") no-repeat center center;
  display: none;
  float: right; }