
.navigation-w {
	@include full;
	margin-top: 0px;
	background: $dark-grey;
	padding: 0;
	visibility: hidden;
	position: absolute;
	z-index: 100;
	top: 0;
	&.open {
		visibility: visible;
		transform: translateZ(0);
		overflow: scroll;
		height: 600px;
		background: $red; }
	@include breakpoint($sub-tablet) {
		display: block;
		visibility: visible;
		position: relative;
		top: 0;
		padding: 0 0; }
	@include breakpoint($sub-desktop) {

		padding: 0 $margin; }
	.navigation {
		@extend .container;
		ul {
			display: block;
			margin: 0;
			padding: 0;
			@include breakpoint($sub-tablet) {
				display: flex;
				flex-direction: row; }

			li {
				list-style: none;
				flex-grow: 1;
				position: relative;
				text-align: center;
				span.ar {
					top: 50%;
					margin-top: -4px;
					right: 10%;
					border: solid transparent;
					content: " ";
					height: 0;
					width: 0;
					position: absolute;
					pointer-events: none;
					border-color: rgba(255, 255, 255, 0);
					border-top-color: #FFFFFF;
					border-width: 8px;
					margin-left: -8px; }
				&.open {
					span.ar {
						transform: rotate(180deg);
						margin-top: -14px; } }
				&:last-child() {
					@include breakpoint($sub-tablet);
					border-right: 1px solid lighten($dark-grey,5%); }

				a {
					padding: $margin/2 $margin;
					text-decoration: none;
					text-align: center;
					color: $almost-white;
					display: BLOCK;
					background: $red;
					border-bottom: 1px solid #eb8488;
					@include breakpoint($sub-tablet) {
						padding: $margin/2 $margin;
						border-bottom: 0px none;
						background: $dark-grey;
						border-left: 1px solid lighten($dark-grey,5%); }
					&:hover {
						@include breakpoint(960px) {
							background: lighten($dark-grey,10%); } } }
				&.hover {
					a {
						background: lighten($dark-grey,10%); } }
				ul.sub-menu {
					visibility: hidden;
					width: 120%;
					z-index: 200;
					background: $red;
					position: absolute;
					top: 100%;
					left: -10%;
					display: block;
					@include boxShadow;
					@include breakpoint($sub-tablet) {
						background: lighten($dark-grey,5%); }
					&.open {
						position: relative;
						float: left;
						visibility: visible;
						margin-bottom: 10px;
						box-shadow: 0px 6px 4px 0px rgba(0,0,0,0.1); }

					&.guides {
						width: 140%;
						left: -20%; }
					&.regions {
						width: 160%;
						left: -30%; }
					li {
						@include full;
						a {
							@include full;
							background: $red;
							@include breakpoint($sub-tablet) {
								background: lighten($dark-grey,5%); }
							&:hover {
								text-decoration: underline; } }

						ul.sub-menu {
							visibility: hidden;
							width: 100%;
							z-index: 200;
							background: $dark-grey;
							position: absolute;
							top: 0;
							left: 100%;
							display: block;
							li {
								@include full;
								a {
									@include full;
									background: lighten($dark-grey,10%);
									&:hover {
										text-decoration: underline; } } } } } } } } } }




