.footer-w {
	@include full;
	padding: 0 $margin;
	background: $dark-grey;
	.footer {
		border-top: 1px solid lighten($dark-grey,5%);
		@extend .container;
		padding: $margin 0;
		font-weight: 300;
		a {
			color: $almost-white;
			text-decoration: none; }
		.col {
			@include full;
			margin-bottom: $margin;
			@include breakpoint($sub-tablet) {
				margin-bottom: 0;
				@include span(2 of 3 nobreak);
				&:nth-child(even) {
					@include span(last 1 of 3 nobreak);
					text-align: right; } }
			ul {
				@include full;
				li {
					@include breakpoint($sub-tablet) {
						float: left;
						margin-right: $margin; }

					a {
						color: $almost-white;
						text-decoration: none; } } } } } }
