/*------------------------------------*\
    $MIXINS
\*------------------------------------*/

/* CSS Transition
	Usage: @include transition(width,0.3s,ease-out);
 */
@mixin transition($transition-property, $transition-time, $method) {
	-webkit-transition: $transition-property $transition-time $method;
	-moz-transition: $transition-property $transition-time $method;
	-ms-transition: $transition-property $transition-time $method;
	-o-transition: $transition-property $transition-time $method;
	transition: $transition-property $transition-time $method;
}

@mixin boxShadow() {
	-webkit-box-shadow: 0px 2px 15px 0px rgba(0,0,0,0.3);
	-moz-box-shadow: 0px 2px 15px 0px rgba(0,0,0,0.3);
	box-shadow: 0px 2px 15px 0px rgba(0,0,0,0.3);
}

/* Border Radius
	Usage: @include br(10px);
*/
@mixin br($radius) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	border-radius: $radius;
}

/*  Font Size
	Usage: @include fontSize(12px, 16px);
*/

@mixin fontSize($font-size, $line-height) {
    font-size: $font-size;
    line-height: $line-height;

}
@mixin backgroundCover() {
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}