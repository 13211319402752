// BREAKPOINTS
$desktop: 1025px;
$desktopWidth: $desktop;
$sub-desktop: 960px;
$desktop-start: max-width $desktop;
$desktop-over: min-width $desktop;
$tablet-start: max-width 800px;
$mobile-start: max-width 600px;
$mobile-over: min-width 700px;
$tablet: 800px;
$sub-tablet: 700px;
$mobile: 500px;
$ipadPortait: 1020px;
$slab: $mobile-over (min-height 670px) (orientation portrait);

$tablet-height: (min-height 750px) (orientation portrait);

// COLOURS - Brand
$red: 			#e46d72;
$deep-red:	#cb1b32;
$dark-grey: 		#333333;
$light-grey: 		#ebebeb;
$green:  		#1ebf86;
$almost-white:   	#FFF;
// COLOURS
$html-background: 	#FFFFFF;
$body-color : 		#69686d;
$heading-color : 	$dark-grey !default;
$highlight-color: 	#2d5696 !default;
$link-color:		$dark-grey;
$border-color:		#D7D3D3 !default;
$success-color:		#00E676 !default;
$error-color:		#FF1744 !default;
$footer-color:		$html-background;
$warning-color:		#FFD54F !default;
$white:				#fff !default;
$placeholder-colour: $body-color !default;

// TYPOGRAPHY
$font: 'Montserrat', 'Open Sans', sans-serif;
$font-secondary: $font;
$heading-font: $font-secondary;
$base-font-size:   14px;
$base-line-height: 22px;

$heading-size-1: 	30px!default;
$heading-line-1:	38px !default;

$heading-size-2: 	28px!default;
$heading-line-2:  	36px !default;

$heading-size-3:  	26px !default;
$heading-line-3:  	32px !default;

$heading-size-4:  20px!default;
$heading-line-4:  26px !default;

$heading-size-5:  22px!default;
$heading-line-5:  26px!default;

$heading-size-6:  20px,!default;
$heading-line-6:  24px !default;

/* Links */
$anchor-color: $link-color !default;
$anchor-color-hover: $body-color !default;
$anchor-text-decoration: none!default;
$anchor-text-decoration-hover: none!default;

// SPACING
$margin : 20px;
$pad: 20px;

/* Heading */
$heading-font: $font!default;
$heading-margin: $margin 0 $margin 0!default;
$heading-weight: 400 !default;

/* Paragraph */
$paragraph-margin: 0 0 $margin 0!default;

/* Small */

$small-margin: 0 0 $margin 0!default;
$small-font-size: 12px;
$small-line-height: 16px;

/* Lists */
$lists-margin: 0 0 $margin $margin;
$sub-lists-margin: $margin/2 0 0 18px;
$list-item-margin: 0 0 $margin/2 0;

/* Blockquote */
$blockquote-color: $body-color !default;
$blockquote-padding: $pad/2 0 0 $pad/2 !default;
$blockquote-margin: 0 0 $margin 0 !default;
$blockquote-font-style: italic !default;
$blockquote-font-size: 24px !default;
$blockquote-line-height: 28px !default;

/* HR */
$hr-bg: #FFF !default;
$hr-margin: $margin*2 0 $margin*2 0 !default;
$hr-height: 0px!default;

/* ABBR */
$abbr-border-bottom: 1px dotted $border-color !default;

/* FIGCAPTION */
$figcaption-font-style: italic !default;
$figcaption-margin: $margin 0 0 0;

/* TABLE */
$table-border: $border-color 1px solid!default;
$table-padding: $margin/2 $margin!default;

/* FORM */

$form-input-border: 1px solid $dark-grey !default;
$form-input-padding: 10px !default;
$form-input-background: $almost-white !default;
$form-input-error: $error-color!default;
$form-input-success: $success-color!default;
$form-input-color: $dark-grey !default;
$form-input-outline: none!default;
$form-input-radius: 0!default;
$form-font-size: 16px;
$label-margin: 0 0 $margin/2 0 !default;
$input-w-margin: 0 0 $margin 0 !default;
$checkbox-background: $almost-white;
$checkbox-background-check: $green;
$select-chevron: $dark-grey;
$checkbox-border: 1px solid $light-grey;
$checkbox-border-hover: 1px solid $green;
/* BUTTONS */

$btn-namespace:       'btn' !default;
$btn-font-weight:     600 !default;

$btn-padding:         $pad !default;
$btn-padding--small:  $btn-padding/2 !default;
$btn-padding--large:  $btn-padding*2 !default;
$btn-background:      $dark-grey !default;
$btn-background-hover:      darken($btn-background,10%) !default;
$btn-color:           $green !default;
$btn-border-width:    0 !default;
$btn-border-style:    solid !default;
$btn-border-color:    $btn-background !default;
$btn-radius:          0 !default;

$enable-btn--small:   true !default;
$enable-btn--large:   true !default;
$enable-btn--full:    true !default;
$enable-btn--pill:    true !default;


// Here we set a variable assuming that `box-sizing: border-box` is not set
// globally. If it has been previously been defined, the following variable will
// be overriden and will be set to `true`.
$global-border-box: false !default;

/* PLUGINS */
/* DROPKICK */
$dk-color-aux: $border-color;
$dk-border: 1px solid $border-color;
$dk-border-color-aux: $border-color;
$dk-border-radius: 0px;
$dk-width: 100%;
$dk-selected-color: $border-color;
$dk-option-padding: $pad;


/* iCHECK */

$icheck-background: #FFF;
$icheck-radio-radius: 100px;
$icheck-width: 15px;
$icheck-height: 15px;
$icheck-border: 1px solid $border-color;
$icheck-checked-background: $border-color;

/* SUSY */
$gutters: 1 / 4;
$susy: (columns: 12, gutters: $gutters, output: float);
