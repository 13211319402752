.sub-footer-w {
	@include full;
	background: $dark-grey;
	color: $almost-white;
	padding: 0 $margin;

	.sub-footer {
		padding: $margin*2 0;
		@extend .container;
		.col {
			@include full;
			margin-bottom: $margin*2;
			@include breakpoint($sub-tablet) {
				margin-bottom: 0; }
			p {
				padding-right: $margin/2;
				margin-bottom: $margin;
				@include fontSize(14px,18px);
				@include breakpoint($sub-tablet $sub-desktop) {
					@include fontSize(12px, 17px); } }
			a.btn {
				color: $almost-white;
				background: lighten($dark-grey,5%);
				border: 1px solid lighten($dark-grey,5%);
				float: left;
				padding: $margin/2 $margin;
				text-decoration: none;
				@include breakpoint($sub-tablet $sub-desktop) {
					@include fontSize(12px, 18px); } }

			@include breakpoint($sub-tablet) {
				@include span(2 of 10 nobreak);

				&:last-child() {
					@include span(last 2 of 10 nobreak); } }

			.sub-footer-title {
				color: $almost-white;
				@include fontSize(16px, 18px);
				margin-bottom: $margin;
				font-weight: 800;
				@include breakpoint($sub-tablet $sub-desktop) {
					@include fontSize(12px, 18px); } }
			ul {
				@include full;
				&.social {
					padding-right: $margin;
					li {
						@include span(2 of 4 nobreak);
						list-style-type: none;
						margin-bottom: $margin/2;


						&:nth-child(even) {
							@include span(last 2 of 4 nobreak); }
						a {
							color: $almost-white;
							text-decoration: none;
							@include full;
							height: 50px;
							background: lighten($dark-grey,5%);
							&.instagram {
								background: url(../images/instagram@2x.png) lighten($dark-grey,5%) center center no-repeat;
								background-size: 21px; }

							&.facebook {
								background: url(../images/facebook@2x.png) lighten($dark-grey,5%) center center no-repeat;
								background-size: 12px; }
							&.youtube {
								background: url(../images/youtube@2x.png) lighten($dark-grey,5%) center center no-repeat;
								background-size: 23px; }
							&.twitter {
								background: url(../images/twitter@2x.png) lighten($dark-grey,5%) center center no-repeat;
								background-size: 21px; } } } }

				li {
					@include full;
					list-style-type: none;
					margin-bottom: 0;
					@include breakpoint($sub-tablet $sub-desktop) {
						@include fontSize(12px, 18px); }
					a {
						color: $almost-white;
						text-decoration: none; } } } } } }
