.search-modal-w, .subscription-modal-w {

	@include full;
	display: none;
	z-index: 10001;
	position: absolute;
	padding: $margin;
	left: 0;
	&.open {
		display: block; }
	.search {
		display: block;
		z-index: 100000000000;
		background: $almost-white;
		padding-top: $margin;
		margin: 0 0;
		width: 100%;
		position: relative;
		float: left;
		&.sub-form {
			@include container(800px);
			float: none;
			overflow: hidden;
			input {
				width: 60%;
				float: left;
				@include breakpoint($sub-tablet) {
					width: 70%; } }
			button.btn {
				width: 40%;
				float: right;

				background: $dark-grey;
				line-height: 25px;
				float: right;
				color: #FFF;
				border: 1px solid $dark-grey;
				@include breakpoint($sub-tablet) {
					width: 30%; } } }

		a.close-search-btn {
		  background: url(../images/icons/close-icon.png) $deep-red center center no-repeat;
		  background-size: 15px;
		  color: $almost-white;
		  padding: $margin/2 $margin;
		  float: right;
		  position: absolute;
		  top: 0;
		  right: 0; }
		.form {
			padding: $margin;
			.search-title {
				color: $deep-red;
				letter-spacing: 1px;
				font-weight: 800;
				@include fontSize(18px,20px);
				text-transform: uppercase;
				margin-bottom: $margin/2; } } } }
